<template>
    <!--编辑页与详情页相同-->

    <div class="RefundDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <div>
                <el-form
                    @submit.native.prevent
                    :inline="true"
                    style="text-align: right"
                    size="small"
                    label-width="80px"
                >
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="退货方式">
                                <el-input readonly v-model="form.chooseType" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="销售机构">
                                <el-input readonly v-model="form.repoName" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="批发订单">
                                <el-input readonly v-model="form.bizCode" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="进货机构">
                                <el-input readonly v-model="form.deptName" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="类型">
                                <el-input readonly v-model="form.type" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="审核状态">
                                <el-input readonly v-model="form.reviewStatus" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="创建人">
                                <el-input readonly v-model="form.creator" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="创建日期">
                                <el-date-picker v-model="form.createTime" value-format="yyyy-MM-dd" readonly />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-button type="primary" @click="handleAudit" size="small">审核详情</el-button>
            <PickupReturnAudit ref="PickupReturnAudit" :review-privilege-flag="'menu.sale.refund.review'" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                v-loading="tableLoading"
                size="mini"
                show-summary
                :highlight-current-row="true"
                max-height="520"
            >
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="商品名称" min-width="160" prop="sku.name" />
                <el-table-column label="条码" width="160" prop="sku.bars">
                    <template slot-scope="scope">
                        <div v-for="b in scope.row.sku.bars" :key="b">{{ b }}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="sku.specs" label="规格" width="100" />
                <el-table-column prop="sku.category" label="类目" width="120" />
                <el-table-column prop="sku.unit" label="单位" width="80" />
                <el-table-column prop="sku.brand" label="品牌" width="100" />
                <el-table-column label="包装因子" width="100" v-if="bigUnitFlag">
                    <template slot-scope="scope">
                        {{ scope.row.packFactors }}
                    </template>
                </el-table-column>
                <el-table-column label="退货价" width="100">
                    <template slot-scope="scope">
                        {{ scope.row.prices | m }}
                    </template>
                </el-table-column>
                <el-table-column label="退货箱数" width="100" prop="bigUnitCounts" v-if="bigUnitFlag" />
                <el-table-column label="散装数量" width="100" prop="bulkCounts" v-if="bigUnitFlag" />
                <el-table-column label="退货总数" width="100" prop="counts" />
                <el-table-column label="退货金额" width="100" prop="refundMoney" />
                <el-table-column label="赠品数量" width="100" prop="giftCounts" />
            </el-table>
        </el-card>
    </div>
</template>
<script>
import PickupReturnAudit from 'components/PickupReturnAudit';
import MoneyUtils from 'js/MoneyUtils';
import decimal from 'decimal';
import UrlUtils from 'js/UrlUtils';

export default {
    name: 'RefundDetail',
    props: ['form'],
    components: { PickupReturnAudit },
    data() {
        return {
            baseUrl: '/trade/wholesale/refund',
            url: {
                queryGoods: '/goods/goods/list_c',
                queryRefund: '/trade/wholesale/refund/info_c/',
            },
            meta: {
                types: [],
            },
            codes: [],
            tableData: [],
            bigUnitFlag: false,
            tableLoading: true,
        };
    },
    mounted() {
        this.codes = this.form.goodsCode;
        if (this.form.chooseType == 2) {
            this.form.chooseType = '引用单据';
        } else if (this.form.chooseType == 1) {
            this.form.chooseType = '引用商品';
        }
        if (this.form.reviewStatus == 0) {
            this.form.reviewStatus = '待审核';
        } else if (this.form.reviewStatus == 2) {
            this.form.reviewStatus = '已审核';
        } else if (this.form.reviewStatus == -1) {
            this.form.reviewStatus = '已拒绝';
        }
        if (this.form.type == 0) {
            this.form.type = '采购退货';
            this.getUnitFlag(this.form.deptCode);
        } else {
            this.form.type = '自建退货';
            this.getUnitFlag(this.form.repoCode);
        }
    },
    methods: {
        getUnitFlag(deptCode) {
            UrlUtils.HasBigUnitPrivilege(this, deptCode, (flag) => {
                this.bigUnitFlag = flag;
            });
            this.handleQuery();
        },
        refund(row) {
            // eslint-disable-next-line new-cap
            const dec = new decimal(row.counts).sub(row.bulkCounts).div(row.packFactors).toString();
            const num = parseInt(dec);
            if (isNaN(num)) {
                return '';
            } else {
                row.bigUnitCounts = num;
            }
            return row.bigUnitCounts;
        },
        handleAudit() {
            this.$refs.PickupReturnAudit.show(this.form.code, this.baseUrl);
        },
        handleQuery() {
            const _this = this;
            _this.$http.get(_this.url.queryRefund + _this.form.code).then((res) => {
                UrlUtils.QueryGoodsInfoByCodes(
                    _this,
                    _this.url.queryGoods,
                    { codes: res.data.data.goodsCode },
                    (data) => {
                        data.forEach((v, i) => {
                            v.packFactors = res.data.data.packFactors[i] ? res.data.data.packFactors[i] : 1;
                            const dbPrice = res.data.data.prices[i];
                            v.prices = MoneyUtils.moneyToYuan(dbPrice);
                            v.bulkCounts = res.data.data.bulkCounts[i];
                            v.counts = res.data.data.counts[i];
                            v.bigUnitCounts = res.data.data.bigUnitCounts[i];
                            v.giftCounts = res.data.data.giftCounts[i];
                            v.refundMoney = MoneyUtils.moneyToYuan(
                                this.decimal(dbPrice || 0)
                                    .mul(v.counts || 0)
                                    .toNumber()
                            );
                        });
                        _this.tableData = data;
                    }
                );
            });
        },
    },
    filters: {
        type(type) {
            if (typeof type == 'undefined') {
                return '';
            }
            if (type == '0') {
                return '标品';
            }
            if (type == '1') {
                return '非标品';
            }
        },
        m(money) {
            return MoneyUtils.formatMoney(money);
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
